@import "../scss-var.scss";

.footer-container {
  display: flex;
  padding: 8rem 15rem;
  background: $color-grey-dark-1;
  color: white;
  font-family: inherit;

  @media only screen and (max-width: $bp-small) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 8rem 6rem;
  }
}

.footer-icon-info {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 2rem;

  @media only screen and (max-width: $bp-small) {
    margin: 0;
    margin-bottom: 2rem;
  }
}

.footer-icon-social {
  width: 1.8rem;
  height: 1.8rem;
}

.footer-info-container {
  display: flex;
  flex-direction: column;
  margin-right: 10rem;

  @media only screen and (max-width: $bp-small) {
    margin: 0;
    margin-bottom: 5rem;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.footer-info-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

.footer-info-details {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $bp-small) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
}

.footer-info-detail {
  &-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 3rem;

    @media only screen and (max-width: $bp-small) {
      margin: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  &-inner-container {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $bp-small) {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  &-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  &-subtitle {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6;

    &:link,
    &:visited {
      color: white;
    }
  }
}

@media only screen and (max-width: $bp-small) {
  .footer-info-detail-container:not(:last-child) {
    margin-right: 2rem;
  }
}

.footer-about-container {
  display: flex;
  flex-direction: column;
  max-width: 30%;

  @media only screen and (max-width: $bp-small) {
    max-width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.footer-about-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

.footer-about-paragraph {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 4rem;
}

.footer-social-container {
  display: flex;
}

.footer-social-bg {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  padding: 1.2rem;
  background-color: $color-grey-dark-2;
  margin-bottom: 4rem;
}

.footer-social-bg:not(:last-child) {
  margin-right: 2rem;
}

.footer-about-copyright {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5;
}
