@import "../scss-var.scss";

.header-content-container {
  min-height: 60rem;
  padding: 10rem 15rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../local-img/header-content.jpg");

  margin-top: 8rem;

  display: flex;
  align-items: center;

  @media only screen and (max-width: $bp-medium) {
    padding: 8rem;
  }

  @media only screen and (max-width: $bp-small) {
    padding: 4rem 6rem;
  }
}

.header-content-info-container {
  max-width: 60%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: $bp-large) {
    max-width: 100%;
  }
}

.header-content-title {
  font-family: inherit;
  font-size: 5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 3rem;

  &-red {
    color: $color-main;
  }

  @media only screen and (max-width: $bp-medium) {
    font-size: 4.8rem;
  }
}

.header-content-paragraph {
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 400;
  color: white;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.header-content-list {
  list-style: inside;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 400;
  color: white;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem;

  @media only screen and (max-width: $bp-medium) {
    text-align: left;
  }
}

.header-content-button {
  padding: 2rem 4.5rem;
  margin-top: 3rem;
  border: none;
  border-radius: 100px;
  background-color: $color-main;
  cursor: pointer;

  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 600;
  color: white;
  text-decoration: none;

  &:link,
  &:visited {
    color: white;
  }
}
