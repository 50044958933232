@import "../scss-var.scss";

.about-container {
  display: flex;
  flex-direction: row;
  padding: 10rem 15rem;
  background: white;

  @media only screen and (max-width: $bp-large) {
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 6rem;
  }
}

.about-img {
  max-width: 49rem;
  max-height: 50rem;
  margin-right: 8rem;
  box-shadow: 1rem 1rem 3rem rgba(0, 0, 0, 0.3);

  @media only screen and (max-width: $bp-large) {
    margin: 0;
    margin-bottom: 8rem;
  }

  @media only screen and (max-width: $bp-smallest) {
    max-width: 29rem;
    max-height: 30rem;
  }
}

.about-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-family: inherit;

  @media only screen and (max-width: $bp-large) {
    width: 70%;
  }

  @media only screen and (max-width: $bp-medium) {
    flex-direction: column;
  }

  @media only screen and (max-width: $bp-small) {
    width: 80%;
  }
}

.about-info {
  &-title {
    font-size: 4rem;
    font-weight: 700;
    color: $color-grey-dark-1;
    margin-bottom: 3rem;
  }

  &-paragraph {
    font-size: 1.3rem;
    font-weight: 400;
    color: $color-grey-dark-3;
    line-height: 1.4;
    margin-bottom: 1.2rem;
  }
}

.about-button {
  padding: 2rem 4.5rem;
  margin-top: 3rem;
  border: none;
  border-radius: 100px;
  background-color: $color-main;
  cursor: pointer;

  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 600;
  color: white;
  text-decoration: none;

  &:link,
  &:visited {
    color: white;
  }
}
