$color-grey-light-1: #faf9f9;
$color-grey-light-2: #f4f2f2;
$color-grey-light-3: #f0eeee;
$color-grey-light-4: #ccc;

$color-grey-dark-1: rgb(32, 32, 32);
$color-grey-dark-2: #777;
$color-grey-dark-3: #999;

$color-main: #c21019;
$color-main-light: #d66167;
$color-main-dark: #9e0f16;

$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px
