@import "../scss-var.scss";

.service-container {
  min-height: 56rem;
  padding: 10rem 15rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../local-img/service.jpg");
  font-family: inherit;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: $bp-medium) {
    padding: 8rem;
  }

  @media only screen and (max-width: $bp-small) {
    padding: 5rem 6rem;
  }
}

.service-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 50%;

  @media only screen and (max-width: $bp-medium) {
    max-width: 100%;
  }
}

.service-subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  color: $color-grey-dark-1;
  margin-bottom: 2rem;
}

.service-description {
  font-size: 1.5rem;
  font-weight: 400;
  color: $color-grey-dark-1;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.service-title {
  font-size: 4.2rem;
  font-weight: 700;
  color: $color-main;
  margin-bottom: 2rem;
}

.service-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: $bp-small) {
    flex-direction: column;
  }
}

.service-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;

  &-number {
    font-size: 5rem;
    font-weight: 300;
    font-style: italic;
    color: $color-grey-light-4;
    margin-bottom: 1rem;
  }

  &-title {
    font-size: 2.2rem;
    font-weight: 700;
    color: $color-grey-dark-1;
    margin-bottom: 2rem;
  }

  &-info {
    font-size: 1.5rem;
    font-weight: 400;
    color: $color-grey-dark-3;
    line-height: 1.5;
  }
}
