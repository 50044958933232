@import "../scss-var.scss";

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2rem 15rem;
  background-color: white;
  position: fixed;
  width: 100%;

  @media only screen and (max-width: $bp-medium) {
    padding: 2rem 8rem;
  }

  @media only screen and (max-width: $bp-small) {
    padding: 2rem 6rem;
  }
}

.header-logo {
  height: 4rem;
  cursor: pointer;
}

.header-list {
  display: flex;

  list-style: none;

  & li:not(:last-child) {
    margin-right: 8rem;

    @media only screen and (max-width: $bp-medium) {
      margin: 0;
    }
  }

  &-home-btn {
    font-family: inherit;
    font-size: 1.4rem;
    color: $color-grey-dark-1;
    font-weight: 700;
    cursor: pointer;

    @media only screen and (max-width: $bp-medium) {
      display: block;
      padding: 2.5rem;
    }
  }

  &-link {
    font-family: inherit;
    font-size: 1.4rem;
    color: $color-grey-dark-1;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;

    @media only screen and (max-width: $bp-medium) {
      display: block;
      padding: 2.5rem;
    }
  }

  &-link:link,
  &-link:visited {
    color: $color-grey-dark-1;
    cursor: pointer;
  }

  @media only screen and (max-width: $bp-medium) {
    flex-direction: column;
    position: absolute;
    top: 7.5rem;
    right: 0;
    width: 100%;
    background-color: white;
    text-align: center;

    //transition: all 0.5s ease-in-out;
  }
}

.header-list-close {
  display: flex;

  list-style: none;

  & li:not(:last-child) {
    margin-right: 8rem;

    @media only screen and (max-width: $bp-medium) {
      margin: 0;
    }
  }

  &-home-btn {
    font-family: inherit;
    font-size: 1.4rem;
    color: $color-grey-dark-1;
    font-weight: 700;
    cursor: pointer;

    @media only screen and (max-width: $bp-medium) {
      display: block;
      padding: 2.5rem;
    }
  }

  &-link {
    font-family: inherit;
    font-size: 1.4rem;
    color: $color-grey-dark-1;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;

    @media only screen and (max-width: $bp-medium) {
      display: block;
      padding: 2.5rem;
    }
  }

  &-link:link,
  &-link:visited {
    color: $color-grey-dark-1;
    cursor: pointer;
  }

  @media only screen and (max-width: $bp-medium) {
    flex-direction: column;
    position: absolute;
    top: 7.5rem;
    right: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    transform: translateX(100%);

    //transition: all 0.5s ease-in-out;
  }
}

.header-icon-container {
  display: none;

  @media only screen and (max-width: $bp-medium) {
    display: flex;
  }
}

.header-icon {
  width: 2rem;
  height: 2rem;
  color: $color-grey-dark-1;
  display: none;
  cursor: pointer;

  @media only screen and (max-width: $bp-medium) {
    display: flex;
  }
}
